const DEFAULT_LOCALE = "en-US";

export const capitalizeFirstLetter = (string) => {
  return string.charAt(0).toUpperCase() + string.slice(1);
};

export const formatDateValue = (isoDateString) => {
  // date inputs expect a format of '%Y-%m-%d', but the db provides UTC iso strings
  // so we generate a new iso date string, then shorten it to match the required format
  const MS_PER_MINUTE = 60000;
  let date = isoDateString ? new Date(isoDateString) : new Date();
  let offset = date.getTimezoneOffset();
  let adjustedDate = new Date(date - offset * MS_PER_MINUTE);
  return adjustedDate.toISOString().slice(0, 10);
};

export const formatDateTimeValue = (isoDateString) => {
  const MS_PER_MINUTE = 60000;
  let date = isoDateString ? new Date(isoDateString) : new Date();
  let offset = date.getTimezoneOffset();
  let adjustedDate = new Date(date - offset * MS_PER_MINUTE);
  return adjustedDate.toISOString().slice(0, 19);
};

export const formatDateString = (dateString) => {
  let date = new Date(dateString);
  const options = { year: "numeric", month: "short", day: "numeric" };
  const locale = navigator.languages[0] || DEFAULT_LOCALE;
  return date.toLocaleDateString(locale, options);
};

export const createNumericDateString = (dateString) => {
  let date = new Date(dateString);
  const options = { year: "2-digit", month: "numeric", day: "numeric" };
  const locale = navigator.languages[0] || DEFAULT_LOCALE;
  return date.toLocaleDateString(locale, options);
};

export const pluralize = (noun, count) => {
  let lastCharacter = noun.toLowerCase()[noun.length - 1];
  let pluralNoun =
    lastCharacter === "y" ? noun.replace(/.$/, "ies") : `${noun}s`;
  return count === 1 ? noun : pluralNoun;
};

export const getSaveOrUpdateEventName = (editing) => {
  return editing ? "update-instance" : "save-instance";
};

export const scrollToTop = () => {
  window.scrollTo(0, 0);
};

export const notEmpty = (obj) => Object.keys(obj).length > 0;

export const toOptions = (items, getValue, getLabel) => {
  const choices = items.map((choice) => ({
    value: getValue(choice),
    label: getLabel(choice),
  }));
  return [{ label: "-----------", value: null }, ...choices];
};

export const validateUrl = (url) => {
  const validURLRegex = /^https?:\/\//;
  return validURLRegex.test(url);
};

export const updateEndDate = (formkit, editing, dateString) => {
  if (!editing && dateString) {
    const node = formkit.get("end_date");
    if (node) {
      let startValue = new Date(dateString);
      let endValue = new Date(node.value);
      if (endValue < startValue || !node.value) {
        const adjustedEndDate = new Date(dateString);
        adjustedEndDate.setHours(23, 59, 59);
        const adjustedISOEndDate = adjustedEndDate.toISOString();
        node.input(formatDateTimeValue(adjustedISOEndDate));
      }
    }
  }
};

export const isImage = (content_type) => {
  return content_type.includes("image/");
};

const fileTypeRegex = /(.*)\.(doc|docx|pdf|ppt|pptx|png|jpg|jpeg|webp|gif)$/g;

export const filenameOnly = (filename) => {
  return filename.replace(fileTypeRegex, "$1");
};

export const fileExtension = (filename) => {
  return filename.replace(fileTypeRegex, "$2");
};

const truncatedString = (input, maxLength) =>
  input.length < maxLength ? input : `${input.substring(0, maxLength)}…`;

export const truncateFileName = (f, maxLength) => {
  if (fileTypeRegex.test(f)) {
    const filename = filenameOnly(f);
    const extension = fileExtension(f);
    return `${truncatedString(filename, maxLength)}.${extension}`;
  }
  return truncatedString(f, maxLength);
};

export const swap = (original, from, to) => {
  const copy = original.concat();
  copy[to] = original[from];
  copy[from] = original[to];
  return copy;
};
