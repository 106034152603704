<template>
  <div class="empty" :class="{ disabled: isDisabled }">
    <p>
      <em><slot></slot></em>
    </p>
    <slot name="action"></slot>
  </div>
</template>

<script>
export default {
  name: "EmptyState",
  props: {
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    isDisabled() {
      return this.disabled;
    },
  },
};
</script>
