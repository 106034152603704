<template>
  <span>
    <spire-button
      @click="show = true"
      :title="triggerText"
      :variant="triggerVariant"
      :sizing="triggerSizing"
      :icon-left="triggerIconLeft"
      :icon-right="triggerIconRight"
      ref="trigger"
      aria-haspopup="true"
      :aria-expanded="showStatusString"
    />
    <slot name="modal" v-if="show" :close-modal="closeModal">
      <spire-modal @close-modal="closeModal">
        <template #header>
          <slot name="header" :close-modal="closeModal"></slot>
        </template>
        <template #content>
          <slot name="content" :close-modal="closeModal"></slot>
        </template>
        <template #footer v-if="$slots.footer">
          <slot name="footer" :close-modal="closeModal"></slot>
        </template>
      </spire-modal>
    </slot>
  </span>
</template>

<script>
export default {
  props: {
    triggerText: {
      type: String,
      required: true,
    },
    triggerSizing: {
      type: String,
      default: "normal",
    },
    triggerVariant: {
      type: String,
      default: "primary",
    },
    hasCloseButton: {
      type: Boolean,
      default: true,
    },
    triggerIconLeft: {
      type: String,
      default: "",
    },
    triggerIconRight: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      show: false,
    };
  },
  methods: {
    closeModal() {
      this.show = false;
    },
    showModal() {
      this.show = true;
    },
  },
  computed: {
    showStatusString() {
      return this.show ? "true" : "false";
    },
  },
  watch: {
    show(newValue) {
      const closing = !newValue;
      if (closing) {
        this.$refs.trigger.$el.focus();
      }
    },
  },
};
</script>
