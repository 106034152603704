<template>
  <div class="combobox-outer-wrapper">
    <div
      v-if="selectedOptions.length"
      class="combobox-selected-options-list alphabetized-columns--4"
    >
      <formulate-combo-box-column
        v-for="(column, index) in columns"
        :key="`combobox-column-${index}`"
        class="combobox-selected-options-column"
        :selected-options="getSortedSelectedOptions(column)"
        @delete-option="deleteOption"
      >
        <template #list-title
          >{{ sortAttribute.label }} <br /><strong
            >{{ column.start }} to {{ column.end }}</strong
          ></template
        >
      </formulate-combo-box-column>
    </div>
    <empty-state v-else>
      {{ emptyStateMessage }}
    </empty-state>
  </div>
</template>

<script>
import EmptyState from "../generic/EmptyState.vue";
import FormulateComboBoxColumn from "./FormulateComboBoxColumn.vue";
import { capitalize } from "lodash";
const DESKTOP_COLUMNS = [
  { start: "A", end: "G" },
  { start: "H", end: "N" },
  { start: "O", end: "U" },
  { start: "V", end: "Z" },
];
const TABLET_COLUMNS = [
  { start: "A", end: "I" },
  { start: "J", end: "R" },
  { start: "S", end: "Z" },
];
const MOBILE_COLUMNS = [
  { start: "A", end: "M" },
  { start: "N", end: "Z" },
];

const MOBILE_MAX = 650;
const TABLET_MAX = 900;
export default {
  props: {
    selectedOptions: {
      type: Array,
      default: () => [],
    },
    sortAttribute: {
      type: Object,
      default: () => ({
        label: "Last Name",
        value: "last_name",
      }),
    },
    emptyStateMessage: {
      type: String,
      default: "No options selected yet!",
    },
  },
  components: {
    EmptyState,
    FormulateComboBoxColumn,
  },
  data() {
    return {
      columns: DESKTOP_COLUMNS,
    };
  },
  emits: ["delete-option"],
  methods: {
    getSortedSelectedOptions(column) {
      return this.selectedOptions.filter((option) => {
        let optionStartingLetter = capitalize(
          option[this.sortAttribute.value][0],
        );
        return (
          optionStartingLetter >= column.start &&
          optionStartingLetter <= column.end
        );
      });
    },
    setColumns(event) {
      let target = event ? event.target : window;
      if (target.innerWidth <= MOBILE_MAX) {
        this.columns = MOBILE_COLUMNS;
      } else if (target.innerWidth <= TABLET_MAX) {
        this.columns = TABLET_COLUMNS;
      } else {
        this.columns = DESKTOP_COLUMNS;
      }
    },
    deleteOption(value) {
      this.$emit("delete-option", value);
    },
  },
  created() {
    this.setColumns();
    window.addEventListener("resize", (event) => {
      this.setColumns(event);
    });
  },
};
</script>
