<template>
  <div>
    <empty-state v-if="!selectedOptions.length">
      {{ emptyStateMessage }}
    </empty-state>
    <ul v-else class="combobox-selected-options-list flex">
      <formulate-combobox-selected-option
        v-for="option in selectedOptions"
        :key="`selection-${option.value}`"
        :option="option"
        @delete-option="deleteOption"
      />
    </ul>
  </div>
</template>

<script>
import EmptyState from "../generic/EmptyState.vue";
import FormulateComboboxSelectedOption from "./FormulateComboboxSelectedOption.vue";
export default {
  props: {
    selectedOptions: {
      type: Array,
      default: () => [],
    },
    emptyStateMessage: {
      type: String,
      default: "No options selected yet!",
    },
  },
  emits: ["delete-option"],
  components: {
    EmptyState,
    FormulateComboboxSelectedOption,
  },
  methods: {
    deleteOption(value) {
      this.$emit("delete-option", value);
    },
  },
};
</script>
