<template>
  <div class="my-3" v-bind="$attrs">
    <ul v-if="categories.length" class="unstyled-list">
      <li
        v-for="category in categories"
        :key="category.id"
        class="full-width-list"
      >
        <accordion
          :default-state="
            selectionsForCategory(category) > 0 ? 'expanded' : 'closed'
          "
          :trigger-id="`category-${category.id}-trigger`"
          :content-id="`category-${category.id}-content`"
          class="full-width max-width compact mx-auto"
          content-classes="preference-exercise-accordion-body"
          :counter="selectionsForCategory(category)"
          unit-type="category"
        >
          <template #trigger_text>{{ category.name }}</template>
          <template #content>
            <div class="competency-card-list">
              <form-kit-preference-exercise-option
                v-for="(option, index) in optionsForCategory(category.id)"
                :key="index"
                :option="option"
                :selected-option-values="selectedOptions"
                :show-dimensions="showDimensions"
                @update-selected-option-values="handleOptionChange"
              />
            </div>
          </template>
        </accordion>
      </li>
      <li class="full-width-list">
        <accordion
          v-if="uncategorizedOptions.length"
          :default-state="uncategorizedSelections > 0 ? 'expanded' : 'closed'"
          trigger-id="uncategorized-trigger"
          content-id="uncategorized-content"
          class="full-width max-width compact mx-auto"
          content-classes="preference-exercise-accordion-body"
          :counter="uncategorizedSelections"
          unit-type="competency"
        >
          <template #trigger_text>Uncategorized Competencies</template>
          <template #content>
            <div class="competency-card-list">
              <form-kit-preference-exercise-option
                v-for="(option, index) in uncategorizedOptions"
                :key="index"
                :option="option"
                :selected-option-values="selectedOptions"
                :show-dimensions="showDimensions"
                @update-selected-option-values="handleOptionChange"
              />
            </div>
          </template>
        </accordion>
      </li>
    </ul>
    <div v-else class="competency-card-list">
      <form-kit-preference-exercise-option
        v-for="(option, index) in options"
        :key="index"
        :option="option"
        :selected-option-values="selectedOptions"
        :show-dimensions="showDimensions"
        @update-selected-option-values="handleOptionChange"
      />
    </div>
  </div>
</template>

<script>
import Accordion from "@/programs/components/generic/Accordion.vue";
import FormKitPreferenceExerciseOption from "./FormKitPreferenceExerciseOption.vue";
export default {
  name: "FormKitPreferenceExerciseOptions",
  props: {
    context: {
      type: Object,
      required: true,
    },
  },
  components: {
    Accordion,
    FormKitPreferenceExerciseOption,
  },
  data() {
    return {
      selectedOptions: [],
    };
  },
  computed: {
    options() {
      return this.context.options;
    },
    uncategorizedOptions() {
      return this.context.uncategorizedOptions;
    },
    showDimensions() {
      return this.context.showDimensions;
    },
    categories() {
      return this.context.categories;
    },
    uncategorizedSelections() {
      return this.selectedOptions.filter((optionId) => {
        return this.uncategorizedOptions.find(
          (option) => option.value == optionId,
        );
      }).length;
    },
  },
  methods: {
    handleOptionChange(value) {
      this.selectedOptions = value;
      this.context.node.input(this.selectedOptions);
    },
    optionsForCategory(categoryId) {
      return this.options.filter((option) => option.categoryId === categoryId);
    },
    selectionsForCategory(category) {
      return this.selectedOptions.filter((optionId) => {
        let competencyOption = this.options.find(
          (option) => option.value == optionId,
        );
        return competencyOption.categoryId === category.id;
      }).length;
    },
  },
  watch: {
    "context.value": {
      immediate: true,
      handler(newValue) {
        this.selectedOptions = newValue;
      },
    },
  },
};
</script>
